// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
@import "./scss/style.scss";

.sidebar {
  font-weight: bold;
  .nav-link {
    &:active {
      background-color: red;
    }
    &:hover {
      background-color: white;
      color: #000c2a;
    }
  }
  .nav-link.active {
    background-color: white;
    color: #000c2a;
  }
}

.dashboard-search-bar {
  width: 30vw;
  height: 40px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #dedede;
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.008), 6.7px 6.7px 5.3px rgba(0, 0, 0, 0.012),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.015), 22.3px 22.3px 17.9px rgba(0, 0, 0, 0.018),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.022), 100px 100px 80px rgba(0, 0, 0, 0.03);
  padding-left: 50px;
  background-image: url(https://i.pinimg.com/originals/19/65/ab/1965ab7ef7de87fe748b3c66efbfc73a.jpg);
  background-repeat: no-repeat;
  background-size: 40px 40px;
  background-position: 2% 45%;
}

.create-activity-container {
  .input-Price {
    padding: 5px;
    background-color: white;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 0.8px solid #dedede;
    padding-left: 10px;
    min-width: 100px;
    min-height: 33px;
  }
  .input-dislay-pill {
    display: flex;
    margin-bottom: 5px;
    .input-container {
      padding: 5px;
      background-color: white;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border: 0.8px solid #dedede;
      padding-left: 10px;
      min-width: 200px;
      min-height: 33px;
    }

    .icon-container {
      padding: 5px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: #000c29;
      width: 40px;
      align-items: center;
      display: flex;
      justify-content: center;
      i {
        color: white;
      }
    }
  }
  .success-button {
    button {
      /* width: 30vw; */
      background-color: #43ec9b;
      padding: 10px;
      font-weight: bold;
      color: #000c28;
      width: 227px;
      margin-top: 30px;
      text-transform: uppercase;
      font-size: 17;
    }
  }
  .react-datepicker {
    border-radius: 10px;
    border: 1px solid #eaeaea;
    box-shadow: 10px 10px 25px -13px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 10px 10px 25px -13px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 25px -13px rgba(0, 0, 0, 0.75);

    .react-datepicker__header {
      background: white;
      border-bottom: none;

      .react-datepicker__day-names {
        border-top: 1px solid #dedede;
        margin-top: 10px;
        font-weight: bold;
        font-size: 10px;
      }
    }
    .react-datepicker__navigation--previous {
      border-radius: 100%;
      background: #000c28;
      height: 25px;
      width: 25px;
      margin-top: 5px;
      span {
        top: 5px;
        right: 1px;
      }
    }
    .react-datepicker__navigation--next {
      border-radius: 100%;
      background: #000c28;
      height: 25px;
      width: 25px;
      margin-top: 5px;

      span {
        top: 5px;
        left: 1px;
      }
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      border-radius: 100%;
      background: #43ec9b !important;
      color: #000c28 !important;
      font-weight: bold;
    }
  }
  .calender-container-input {
    padding: 5px;
    background-color: white;
    border-radius: 8px;
    border: 0.8px solid #dedede;
    padding-left: 10px;
    // min-width: 172px;
    min-height: 33px;
  }
  .percentage-select {
    min-width: 68px !important;
    border-right: none;

    .css-1s2u09g-control {
      box-shadow: none !important;
      border-color: #dedede !important;
      border-radius: 10px !important;
    }

    .css-319lph-ValueContainer {
      padding: 0px 6px !important;
    }
  }
}

.tournament-pill {
  background: #43ec9b;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: #000c28;
  margin-left: 3px;
}
.left-radius-curve {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.right-radius-curve {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.font-bold {
  font-weight: bold;
}

.icon-container-percent {
  position: relative;
  right: 37px;
  // padding: 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #000c29;
  width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  i {
    color: white;
  }
}
.icon-container-currency {
  position: relative;
  right: 37px;
  // padding: 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #000c29;
  width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  // padding: 11px;
  i {
    color: white;
  }
}
.icon-container-player {
  position: relative;
  right: -100px;
  padding: 12px;
  top: -38px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #000c29;
  width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  i {
    color: white;
  }
}
.icon-container-player-update {
  position: relative;
  right: -135px;
  padding: 12px;
  top: -38px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #000c29;
  width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  i {
    color: white;
  }
}

.user-profile-avatar {
  // @media screen and (max-width: $small) {
  // display: none !important;
  flex-direction: column;
  display: "flex";
  .col {
    display: flex;
    justify-content: center;
  }
  // }
}
