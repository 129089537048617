@font-face {
    font-family: 'NoirPro';
    src: local('NoirPro'), url('./assets//fonts/NoirPro-Regular.woff') format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
    font-family: 'NoirPro-SemiBold';
    src: local('NoirPro-SemiBold'), url('./assets//fonts/NoirPro-SemiBold.woff') format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
    font-family: 'NoirPro-Heavy';
    src: local('NoirPro-Heavy'), url('./assets//fonts/NoirPro-Heavy.woff') format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
    font-family: 'NoirPro-Medium';
    src: local('NoirPro-Medium'), url('./assets//fonts/NoirPro-Medium.woff') format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
    font-family: 'NoirPro-Bold';
    src: local('NoirPro-Bold'), url('./assets//fonts/NoirPro-Bold.woff') format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
    font-family: 'NoirPro-Light';
    src: local('NoirPro-Light'), url('./assets//fonts/NoirPro-Light.woff') format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

body, html { 
    font-family: 'NoirPro' !important;
}

.swap-table tr td{
    border: 1px solid #eaeaea;
    padding: 10px;
    text-align: center;
}
