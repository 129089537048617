.subscribeSection {
  background: #000c28;
  height: 100vh;
  text-align: center;
  color: #fff;

  .row {
    margin-right: 0;
  }
  h3 {
    margin-top: 10vh;
  }
  .subscribeCard {
    background: #efefef;
    box-shadow: 0px 0px 6px -1px #00000026;
    -webkit-box-shadow: 0px 0px 5px -1px #00000026;
    -moz-box-shadow: 0px 0px 5px -1px #00000026;

    .subscribeBtn {
      background: #42ed9b;
      border: none;
      margin-top: 50px;
      color: #000c28;
    }
  }
  .cardForDetails {
    padding: 0px !important;
  }
  .subscribeBtn {
    background: #42ed9b;
    border: none;
    margin-top: 50px;
    color: #000c28;
  }
}
