.plans {
  //  background: #000b29;
  background-position: center;
  background-size: cover;
  color: #fff;
  height: auto;
  min-height: 100vh;

  .row {
  }
  .title {
    color: #fff;
    font-weight: bold;
  }
  .stickTab {
    border-radius: 5px;
    position: absolute;
    color: #000;
    top: -10px;
    background: #42ed9b;
    padding: 3px 10px;
    right: 55px;
  }
  .planCard {
    padding: 10px 10px;
    background: #fff;
    color: #000;
    border-radius: 10px;
    min-height: 550px;
    .packageHeading {
      margin-top: 45px;
    }
    .yourPackage {
      background: #42ed9b;
      text-align: center;
      align-items: center;
      position: relative;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      top: -10px;
      margin-right: -10px;
      margin-left: -10px;
    }
    label {
      font-size: 13px;
    }
    i {
      color: #42ed9b;
      font-size: 15px;
      margin-left: 12px;
    }

    hr {
      border: 1px solid gray;
    }

    .disabledPlan {
      color: grey;
      i {
        color: gray;
        font-size: 20px;
        margin-left: 10px;
      }
    }

    .endDiv {
      label {
        margin-bottom: 0;
        font-size: 18px;
      }
      p {
        font-size: 12px;
      }
      .planBtn {
        background: #42ed9b;
        width: 100%;
        font-weight: bold;
        text-transform: uppercase;
        font-family: NoirPro-SemiBold;
        font-size: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .planBtnDisabled {
        background: #efefef;
        width: 100%;
        text-transform: uppercase;
        font-family: NoirPro-SemiBold;
        font-size: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  .plansbox {
    p {
      font-size: 10px;
    }
    label {
      font-weight: bold;
    }
  }
  .planCardSelected {
    padding: 10px 10px;
    background: #fff;
    color: #000;
    border-radius: 10px;
    min-height: 550px;
    border: 3px solid #42ed9b;

    .packageHeading {
      margin-top: 30px;
    }
    h6 {
      margin-bottom: 0px;
      text-transform: uppercase;
      font-size: 13px;
    }
    .yourPackage {
      background: #42ed9b;
      text-align: center;
      align-items: center;
      position: relative;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      top: -10px;
      margin-right: -10px;
      margin-left: -10px;
    }
    label {
      font-size: 13px;
    }
    i {
      color: #42ed9b;
      font-size: 15px;
      margin-left: 12px;
    }

    hr {
      border: 1px solid gray;
    }

    .disabledPlan {
      color: grey;
      i {
        color: gray;
        font-size: 12px;
        margin-left: 10px;
      }
    }

    .endDiv {
      label {
        margin-bottom: 0;
        font-size: 18px;
      }
      p {
        font-size: 12px;
      }
      .planBtn {
        background: #42ed9b;
        width: 100%;
        font-weight: bold;
        text-transform: uppercase;
        font-family: NoirPro-SemiBold;
        font-size: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .planBtnDisabled {
        background: #efefef;
        width: 100%;
        text-transform: uppercase;
        font-family: NoirPro-SemiBold;
        font-size: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
h4 {
  font-size: 25px;
}
.newPlan {
  label {
    font-size: 12px !important;
    font-weight: bold !important;
    text-transform: uppercase;
  }
  p {
    font-size: 12px !important;
  }
  i {
    position: relative;
    font-size: 15px;
    left: -9px;
  }
  .planCardSelected .yourPackage {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .planCard .endDiv {
  }
  .planCardSelected .endDiv {
  }
  .endDiv {
    position: absolute;
    width: 93%;
    bottom: 20px;
  }
}
